/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";

export default function Index() {
  return (
    <>

      <section className=" relative bg-blueGray-100">

        <div className="justify-center text-center flex flex-wrap py-20">
          <div className="w-full md:w-6/12 px-12 md:px-4">
            <img
                src={require("assets/img/onetap-draft.png").default}
                className=""
                alt="..."
            ></img>
            <h2 className="font-semibold text-4xl">Coming Soon</h2>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-blueGray-500">

            </p>
          </div>
        </div>
      </section>



    </>
  );
}
