import React from "react";

import Navbar from "components/Navbars/profileNavBar.js";
import Footer from "components/Footers/FooterSmall.js";

export default function FBS() {
    return (
        <>

            <main className="profile-page">
                <section className="relative block min-h-screen-35"  style={{ minHeight: "400px" }} >
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-contain"
                        style={{
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",
                            backgroundPosition: "70% center",
                            backgroundImage:
                                "url('https://lh3.googleusercontent.com/p/AF1QipOTAqqaYiA1sn2cImAW2el3-2pe_VhRtsJDZaeL=s680-w680-h510')",
                        }}
                    >

            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            ></span>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-blueGray-200 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </section>
                <section className="relative py-16 bg-blueGray-200">
                    <div className="container mx-auto px-4">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64 z-40">
                            <div className="px-6">
                                <div className="flex flex-wrap justify-center">
                                    <div className="w-full lg:w-3/12 px-4 mb-12 pb-4 flex justify-center">
                                        <div className="relative">
                                            <img
                                                alt="..."
                                                src={require("assets/img/fbs.png").default}
                                                className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                                            />
                                        </div>
                                    </div>

                                </div>


                                <div className="text-center mt-12 mb-24">
                                    <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                        Falls Barbershop
                                    </h3>
                                    <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-600 font-bold uppercase">
                                        <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-600"></i>{" "}
                                        5832 Ferry St<br/>Niagara Falls, ON L2G 1S9
                                    </div>
                                    <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-600 font-bold uppercase">
                                        <i className="fas fa-phone mr-2 text-lg text-blueGray-600"></i>{" "}
                                        (289) 271-8353
                                    </div>

                                    <div className="mt-10 mb-5">
                                        <a href={require("assets/vcf/vcard.vcf").default} style={{backgroundColor: "#be1e2d"}} className="bg-fbs-red text-white active:bg-lightBlue-600 font-bold uppercase text-base px-12 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                            <i className="fas fa-address-book mr-4"></i>
                                            Save Contact
                                        </a>
                                    </div>

                                    <div className="mt-10 mb-5">
                                        <a href={'https://web.getsquire.com/book/falls-barbershop-niagara-falls'} target={'_blank'} style={{backgroundColor: "#be1e2d"}} className="bg-fbs-red text-white active:bg-lightBlue-600 font-bold uppercase text-base px-12 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                            <i className="fas fa-calendar-check mr-4"></i>
                                            Book Appointment
                                        </a>

                                    </div>


                                    <div className="mt-10 mb-5">
                                        <a href={'https://search.google.com/local/writereview?placeid=ChIJN_eUy21D04kRTZvnw44-oXY'} target={'_blank'} style={{backgroundColor: "#be1e2d"}} className="bg-fbs-red text-white active:bg-lightBlue-600 font-bold uppercase text-base px-12 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                            <i className="fas fa-star mr-4"></i>
                                            Leave us a review
                                        </a>
                                    </div>




                                    <div className="mt-10 mb-5">
                                        <a href={'https://www.google.com/maps/dir//fallsbarbershop/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x89d3436dcb94f737:0x76a13e8ec3e79b4d?sa=X&ved=2ahUKEwjTvbn4sN34AhXHg4kEHfroBS4Q9Rd6BAhWEAU'} target={'_blank'} style={{backgroundColor: "#be1e2d"}} className="bg-fbs-red text-white active:bg-lightBlue-600 font-bold uppercase text-base px-12 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                            <i className="fas fa-map-marker-alt mr-4"></i>
                                           Find us
                                        </a>
                                    </div>



                                    <div className="mt-10 mb-5">
                                        <a href={'https://www.instagram.com/fallsbarbershop/'} target={'_blank'} style={{backgroundColor: "#be1e2d"}} className="bg-fbs-red text-white active:bg-lightBlue-600 font-bold uppercase text-base px-12 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                            <i className="fab fa-brands fa-instagram mr-4"></i>
                                           Instagram
                                        </a>
                                    </div>

                                    <div className="mt-10 mb-5" >
                                        <a href={'https://www.facebook.com/fallsbarbershop'} target={'_blank'} style={{backgroundColor: "#be1e2d"}} className="bg-fbs-red text-white active:bg-lightBlue-600 font-bold uppercase text-base px-12 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                            <i className="fab fa-brands fa-facebook mr-4"></i>
                                            Facebook
                                        </a>
                                    </div>



                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
