import React from "react";

import Navbar from "components/Navbars/profileNavBar.js";
import Footer from "components/Footers/FooterSmall.js";


export default function Profile() {
    return (
        <>

            <main className="profile-page">
                <section className="relative block min-h-screen-35"  style={{ minHeight: "320px" }} >
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-contain"
                        style={{
                            backgroundImage:
                                "url('https://www.hector.tech/assets/img/shattered.png')",
                        }}
                    >

            <span
                id="blackOverlay"
                className="w-full h-full absolute opacity-50 bg-black"
            ></span>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-blueGray-200 fill-current"
                                points="2560 0 2560 100 0 100"
                            ></polygon>
                        </svg>
                    </div>
                </section>
                <section className="relative py-16 bg-blueGray-200">
                    <div className="container mx-auto px-4">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64 z-40">
                            <div className="px-6">
                                <div className="flex flex-wrap justify-center">
                                    <div className="w-full lg:w-3/12 px-4 mb-12 pb-4 flex justify-center">
                                        <div className="relative">
                                            <img
                                                alt="..."
                                                src={require("assets/img/profile.jpeg").default}
                                                className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                                            />
                                        </div>
                                    </div>

                                </div>


                                <div className="text-center mt-12">
                                    <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">
                                        Hector Feliz
                                    </h3>
                                    <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                                        <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
                                        Niagara Falls, ON
                                    </div>


                                    <div className="mt-10 mb-5">
                                        <a href={require("assets/vcf/hectorfeliz.vcf").default} className="bg-lightBlue-500 text-white active:bg-lightBlue-600 font-bold uppercase text-base px-12 py-3 rounded shadow-md hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                                            <i className="fas fa-address-book mr-4"></i>
                                            Save Contact
                                        </a>

                                    </div>

                                    <div className="mb-2 text-blueGray-600 mt-10">
                                        <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
                                        Frontend Developer at RBCx (Royal Bank of Canada)
                                    </div>
                                    <div className="mb-2 text-blueGray-600 ">
                                        <i className="fas fa-briefcase mr-2 text-lg text-blueGray-400"></i>
                                        Owner/Operator at OneTap Cards
                                    </div>

                                    <div className="mb-2 text-blueGray-600">
                                        <i className="fas fa-university mr-2 text-lg text-blueGray-400"></i>
                                        Niagara College / Juno College of Technology
                                    </div>
                                </div>

                                <div className="mt-10 py-10 border-t border-blueGray-200 text-center z-40">
                                    <div className="flex flex-wrap justify-center">
                                        <div className="w-full lg:w-9/12 px-4">
                                            <p className="mb-4 text-lg leading-relaxed text-blueGray-700">
                                                I am a versatile web developer with over 7 years of experience in development, eCommerce, Wordpress, digital strategy, email, databases, including various tools and frameworks.

                                                </p>
                                            <p  className="mb-4 text-lg leading-relaxed text-blueGray-700">
                                                I operate on all stages of development and take web projects from concept to production.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
